<template>
  <v-container
    fluid
    class="primary"
    style="position: fixed; min-height: 100vh; overflow-y: scroll"
  >
    <v-row style="height: 100vh" class="ml-6">
      <v-col class="ml-6 d-flex flex-column justify-start">
        <v-card elevation="1" class="mb-12">
          <v-card-title>CMS Snapshots</v-card-title>
          <v-card-subtitle>Archived CMS History</v-card-subtitle>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :items="snapshots"
              item-key="_id"
              class="elevation-1"
            >
              <template v-slot:body="{ items }">
                <tbody>
                  <tr
                    :class="key === selectedRow ? 'custom-highlight-row' : ''"
                    @click="rowSelect(key)"
                    v-for="(item, key) in items"
                    :key="item._id"
                  >
                    <td>{{ friendlyDateTime(item.createdOn) }}</td>
                    <td>{{ item.name }}</td>
                    <td>
                      <v-btn
                        class="ma-2"
                        outlined
                        color="indigo"
                        @click="viewSnapshot(item._id)"
                      >
                        View
                      </v-btn>
                      <v-btn
                        class="ma-2"
                        outlined
                        color="indigo"
                        @click="copySnapshot(item._id)"
                      >
                        Copy Link
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
              <!-- <template v-slot:item.createdOn="{ item }">
                {{ friendlyDateTime(item.createdOn) }}
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn
                  class="ma-2"
                  outlined
                  color="indigo"
                  @click="viewSnapshot(item._id)"
                >
                  View
                </v-btn>
                <v-btn class="ma-2" outlined color="indigo">
                  Copy Link
                </v-btn>
              </template> -->
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col v-if="snapshot.name" class="d-flex flex-column justify-start">
        <v-card elevation="1" class="mb-12">
          <v-card-title> {{ snapshot.name }}</v-card-title>
          <v-card-subtitle>
            {{ friendlyDateTime(snapshot.createdOn) }}</v-card-subtitle
          >
          <v-card-text v-html="snapshot.cms"> </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const axios = require("axios").default;
axios.defaults.withCredentials = true;
import helpers from "@/plugins/helpers";
export default {
  name: "Snapshots",
  components: {},
  data: () => ({
    selectedRow: null,
    snapshots: [],
    headers: [
      { text: "Date", value: "createdOn" },
      {
        text: "Title",
        align: "start",
        value: "name"
      },
      { text: "Action", value: "action" }
    ],
    snapshot: {}
  }),
  computed: {
    token() {
      return this.$store.state.user.token;
    },
    role() {
      return this.$store.state.user.role;
    }
  },
  methods: {
    rowSelect(idx) {
      this.selectedRow = idx;
    },
    friendlyDate(d) {
      return helpers.formatFriendlyDate(d);
    },
    friendlyDateTime(d) {
      return helpers.formatFriendlyDateTime(d);
    },
    viewSnapshot(id) {
      this.snapshot = {};
      const data = this.snapshots.filter(s => s._id === id);
      this.snapshot = data[0];
    },
    copySnapshot(id) {
      const url = `${window.location.host}/snapshots/${id}`;
      // copy URL to clipboard
      navigator.clipboard.writeText(url);
      // toast
      this.$toasted.show("Link Copied").goAway(2500);
    }
  },
  mounted: function() {
    let vm = this;
    // query api for snapshots
    axios.defaults.headers.common = { Authorization: `Bearer ${vm.token}` };
    axios.get(vm.$root.urls.api + "/snapshots", {}).then(function(results) {
      vm.snapshots = results.data;

      // check for snapshot id param. if found, view snapshot
      const snapid = vm.$route.params.snapid;
      if (snapid) {
        vm.viewSnapshot(snapid);
      }
    });
  }
};
</script>

<style>
.custom-highlight-row {
  background-color: #eeeeee;
}
</style>
